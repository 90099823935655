import React, { useRef, useState } from "react";
import { useQuery } from 'react-query'
import axios from 'axios'
import { apiDomain, convertToGoDate } from './../Utilities/Constants'
import Error404 from './../Utilities/Error404'
import { DateRangePicker, Loader } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";

import './SensorGraph.scss';
import {
  AnimatedAxis,
  AnimatedGrid,
  AnimatedLineSeries,
  Tooltip,
  XYChart
} from "@visx/xychart";

type DateRange = [Date, Date];

interface SensorModel {
  sensorid: string,
  label: string,
  unit: string,
  measurements: Measurement[],
}

interface Measurement {
  Sensorid: string,
  TimeStamp: Date,
  Measurement: number
}

interface SensorProps {
  sensorID: string,
  allSensors: Map<string, string>,
  getSensorNameCallback: (sensorName: string, sensorID: string) => void
}

async function fetchSensor(sensorID: string | undefined, start: Date, end: Date): Promise<SensorModel> {
  if (typeof sensorID === 'undefined') {
    return Promise.reject(new Error("Undefined sensor ID"));
  }
  const startStr = convertToGoDate(start);
  const endStr = convertToGoDate(end);
  const path = apiDomain() + "sensor/" + sensorID + "/" + startStr + "/" + endStr;
  const { data } = await axios.get(path);
  return data.data;
}

const tickLabelOffset = 10;

const accessors = {
  xAccessor: (d: Measurement) => new Date(d.TimeStamp),
  yAccessor: (d: Measurement) => d.Measurement
};

let start = new Date(Date.now());
start.setDate(start.getDate() - 7);

const SensorGraph = (props: SensorProps) => {
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(new Date(Date.now()));

  const isLoadingFirstTime = useRef(true);

  let { data, error, isError, isLoading} = useQuery<SensorModel, Error>(
    ['sensor', props.sensorID, startDate, endDate], () => fetchSensor(props.sensorID, startDate, endDate)
  );

  function setDateRange(dateRange: DateRange | null) {
    if (dateRange == null) {
      return;
    }
    setStartDate(dateRange[0]);
    setEndDate(dateRange[1]);
  }

  if (isError) {
    console.log(error?.message);
    return <div><Error404 /></div>;
  }
  else if (isLoading && isLoadingFirstTime.current) {
    isLoadingFirstTime.current = false;
    return <div><Loader center size="lg" /></div>;
  }
  if (data?.unit === 'C' || data?.unit === 'F') {
    data.unit = '°' + data.unit;
  }
  return (
    <div>
      <div className='tc pt2 pb1'>
        <p>
          {`${data?.label} (${data?.unit})`}
        </p>
      </div>
      <div className='flex items-center justify-end'>
          <DateRangePicker
            placement="bottomEnd"
            preventOverflow={true}
            block={true}
            cleanable={false}
            appearance="subtle"
            showOneCalendar
            onChange={(dateRange: DateRange | null) => setDateRange(dateRange)}
            defaultValue={[startDate, endDate]}
          />
      </div>
      <XYChart
        height={270}
        margin={{ left: 60, top: 35, bottom: 38, right: 27 }}
        xScale={{ type: "time" }}
        yScale={{ type: "linear" }}
      >
        <AnimatedGrid
          columns={false}
          numTicks={4}
          lineStyle={{
            stroke: "#d1d1d1",
            strokeLinecap: "round",
            strokeWidth: 1
          }}
          strokeDasharray="0, 4"
        />
        <AnimatedAxis
          hideAxisLine
          hideTicks
          orientation="bottom"
          tickLabelProps={() => ({ dy: tickLabelOffset })}
          left={30}
          numTicks={4}
          animationTrajectory='min'
        />
        <AnimatedAxis
          hideAxisLine
          hideTicks
          orientation="left"
          numTicks={4}
          tickLabelProps={() => ({ dx: -10 })}
          animationTrajectory='min'
        />

        <AnimatedLineSeries
          stroke="#008561"
          dataKey="primary_line"
          data={data?.measurements ? data?.measurements : []}
          {...accessors}
        />
        <Tooltip
          snapTooltipToDatumX
          snapTooltipToDatumY
          showSeriesGlyphs
          glyphStyle={{
            fill: "#008561",
            r: 10,
          }}
          renderTooltip={({ tooltipData }) => {
            return (
              <div className='TooltipContainer'>
                {Object.entries(tooltipData?.datumByKey ? tooltipData?.datumByKey : []).map((lineDataArray) => {
                  const [key, value] = lineDataArray;
                  const unkownValue = value.datum as unknown;
                  const measValue = unkownValue as Measurement;
                  return (
                    <div className="row" key={key}>
                      <div className="date">
                        {format(accessors.xAccessor(measValue), "yyyy MMM d HH:mm")}
                      </div>
                      <div className="value">
                        <div className="ColoredSquare" color="#008561" />
                        {accessors.yAccessor(measValue)}
                        {data?.unit}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          }}
        />
      </XYChart>
    </div>
  );
}

export default SensorGraph;