import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from './Components/Home/Home';
import About from './Components/About/About';
import Error404 from './Components/Utilities/Error404';
import Garden from './Components/Garden/Garden';
import UserPage from './Components/UserPage/UserPage';


class Main extends React.Component {
  render() {
    return (
<div className="hella-h-w-transitions-03 ph2 pb5 mw7-ns mw8-l center mb-5 min-h-100" >
      <Routes > {/* The Switch decides which component to show based on the current URL.*/}
        <Route path='*' element={<Error404 />}/>
        <Route path="/" element={<Home />}/>
        <Route path="/about" element={<About />}/>
        <Route path="/user/:userID" element={<UserPage />}/>
        <Route path="/garden/:id" element={<Garden />}/>
      </Routes>
      </div>
    );
  }
}

export default Main;