
export function apiDomain(): string {
  const production = process.env.NODE_ENV === 'production'
  return production ? 'https://gardenwatch.ca/api/' : 'http://localhost:5000/api/'
}

// returns a date in the format YYYY-MM-DD
export function convertToGoDate(date: Date): string {
  date = new Date(Date.UTC(
    date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()
  ));
  return date.toISOString().split('T')[0];
}