import React from 'react';

const UserPage = () => {

  return (
    <div>
      <p>UserPage</p>
    </div>
  );
}

export default UserPage;