import React from 'react';

const Error404 = () => {
  return (
    <div>
    <header className="tc ph5 lh-copy">
        <h1 className="f1 f-headline-l code mb3 fw9 dib tracked-tight green">404</h1>
        <h2 className="tc f1-l fw1">You've gone over the garden wall!</h2>
    </header>
    </div>
  );
}

export default Error404;