import React from 'react';

const CuteLoader = () => {
  return (
    <div className="tc center">
      <div className="tc spinner-grow mt5 mb1 text-success" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default CuteLoader;