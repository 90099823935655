import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import './ParticlesBG.scss'


const ParticlesBG = () => {
  const particlesInit = async (main: any) => {
    console.log(main);
    await loadFull(main);
  }
  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      options={{
        backgroundMask: {
          enable: true,
          cover: {
            value: {
              r: 255,
              g: 255,
              b: 255
            }
          }
        },
        background: {
          image:
            "linear-gradient(180deg,  rgba(79,220,74,1) 0%, rgba(150,218,74,1) 80%, rgba(208,208,33,1) 100% ",
          size: "100% 100%",
          repeat: "no-repeat"
        },
        fullScreen: {
          enable: true,
          zIndex: -1
        },
        particles: {
          color: {
            value: "#000000",
            animation: {
              enable: true,
              speed: 1
            }
          },
          move: {
            direction: "bottom",
            enable: true,
            outModes: {
              default: "out"
            },
            size: true,
            speed: {
              min: 1,
              max: 3
            }
          },
          number: {
            value: 10,
            density: {
              enable: true,
              area: 800
            }
          },
          opacity: {
            value: 1,
            animation: {
              enable: false,
              startValue: "max",
              destroy: "min",
              speed: 0.3,
              sync: true
            }
          },
          rotate: {
            value: {
              min: 0,
              max: 360
            },
            direction: "random",
            // move: true,
            animation: {
              enable: true,
              speed: 20
            }
          },
          tilt: {
            direction: "random",
            enable: true,
            // move: true,
            value: {
              min: 0,
              max: 360
            },
            animation: {
              enable: true,
              speed: 20
            }
          },
          shape: {
            type: ["polygon"],
            polygon: {
              sides: 6
            }
          },
          size: {
            value: {
              min: 3,
              max: 6
            }
          },
          roll: {
            darken: {
              enable: true,
              value: 30
            },
            enlighten: {
              enable: true,
              value: 30
            },
            enable: true,
            speed: {
              min: 10,
              max: 20
            }
          },
          wobble: {
            distance: 30,
            enable: true,
            // move: true,
            speed: {
              min: -15,
              max: 15
            }
          }
        }
    }}
    className="particles" />
  );
}


export default ParticlesBG;
