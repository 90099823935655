import React from 'react';

const Home = () => {

  return (
    <div>
      <p>Home</p>
    </div>
  );
}

export default Home;