import React from 'react';
import './Footer.scss'

const Footer = () => {

  return (
    <div>
      <div className="text-white ph3 ph5-ns tc sticky-footer">
        <p className="white">Made by <a
          className="as-hover-green no-underline"
          href='https://www.andyschneider.ca'>Andy Schneider</a>
        </p>
      </div>
    </div>
  );
}

export default Footer;