import React from 'react'
import { useQuery } from 'react-query'
import axios from 'axios'
import { apiDomain } from './../Utilities/Constants'
import Error404 from './../Utilities/Error404'
import CuteLoader from './../Utilities/CuteLoader'
import { useParams } from "react-router-dom";
import { stringify } from 'querystring'
import SensorGraph from './../SensorGraph/SensorGraph'

let gardenID: string;

async function fetchGarden() {
  const path = apiDomain() + "garden/" + gardenID;
  const { data } = await axios.get(path);
  return data.data;
}

interface GardenModel {
  name: string,
  description: string,
  public: boolean,
  cutelink: string,
  sensorids: string[],
}

const Garden = () => {

  let sensorIDToNameMap: Map<string, string>;
  sensorIDToNameMap = new Map<string, string>();
  const handleGetSensorName = (sensorName: string, sensorID: string) => {
    sensorIDToNameMap.set(sensorID, sensorName);
  }

  const { id } = useParams();
  gardenID = id ? id : "";
  const { data, error, isError, isLoading } = useQuery<GardenModel, Error>('garden', fetchGarden);
  // first argument is a string to cache and track the query result
  if (isError) {
    console.log(error.message);
    return <div><Error404 /></div>;
  }
  else if (isLoading) {
    return <div><CuteLoader /></div>;
  }
  // const componentsElements = components.map(Component => (
  //   <Component key={shortid.generate()} articleIsPending={articleIsPending}
  //     articleData={articleData} articleError={articleError}/>
  // ));
  // console.log(articleData)
  return (
    <div>
      <h1 className='mb3 fw9 dib tracked-tight green f2'>{data?.name}</h1>
      <h2 className='fw1 f3'>{data?.description}</h2>
      {/* <h3>{data?.public}</h3> */}
      {
        data?.sensorids.map((sensorID, index) => {
          return <SensorGraph key={sensorID}
                  sensorID={sensorID}
                  allSensors={sensorIDToNameMap}
                  getSensorNameCallback={handleGetSensorName}/>
        })
      }
    </div>
  );
}

export default Garden;