import React from 'react';
import Main from './Main';
import Navigation from './Components/Navigation/Navigation';
import Footer from './Components/Footer/Footer';
import ParticlesBG from './Components/ParticlesBG/ParticlesBG';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import './App.scss';

const initialState = {
  box: {},
  route: 'home', // controls where we are in the page
  isSignedIn: false,
  user: {
    id: '',
  }
};

function App() {
  
  return (
    <div>
      <ParticlesBG />
      <div className='top-navigation'>
        {/* <ExpandableSearch />
        <UserCorner isSignedIn={initialState.isSignedIn}/> */}
      </div>
      <div id="MainContent" className="vh-100">
        <ScrollToTop>
          <Main />
        </ScrollToTop>
        <Footer />
      </div>
    </div>
  );
}

export default App;
